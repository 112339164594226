/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer12 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      first: file(relativePath: { eq: "hair-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 389, quality: 100, formats: [WEBP])
        }
      }
      second: file(relativePath: { eq: "hair-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 389, quality: 100, formats: [WEBP])
        }
      }
      third: file(relativePath: { eq: "hair-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 389, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.mezoterapiaSkoryGlowy}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Mezoterapia skóry głowy - stymulacja wzrostu włosów
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="mezoterapia igłowa skóry głowy dr cyj ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>Wskazania</Subtitle>
            <Paragraph>
              <b>Mezoterapia skóry głowy</b> przy użyciu preparatów do tego
              specjalnie przeznaczonych skutecznie ogranicza wypadanie włosów i
              łysienie. Wspomaga odrastanie włosów, poprzez rewitalizację
              cebulek włosowych i stymulacje krążenia. Zapobiega problemom
              związanym ze skórą głowy i zwiększa gęstość oraz grubość włosów.
              Stymuluje i wzmacnia cebulki włosa. Nawilża i odżywia włosy i
              skórę głowy. Przeznaczona jest zarówno dla kobiet, jak i mężczyzn,
              zarówno w przypadku łysienia androgenowego, ale także
              spowodowanego stresem, złą dietą, częstym farbowaniem lub też
              niewłaściwą pielęgnacją włosów i skóry głowy.
            </Paragraph>
            <Paragraph>
              Skuteczność <b>regenerującego włosy koktajlu</b> gwarantuje jego
              skład bogaty w aktywne składniki oraz wykorzystujący zaawansowaną
              biotechnologię. Produkt bogaty jest w 52 aktywnych składników
              (witaminy, aminokwasy, minerały, kwasy nukleinowe, czynniki
              redukujące) oraz 4 peptydy biomimetyczne.
            </Paragraph>
            <Paragraph>
              Terapia włosów za pomocą <b>preparatu peptydowego</b> to
              innowacyjny zabieg hamujący wypadanie włosów. Wysoka skuteczność
              zabiegu wiąże się z zastosowaniem innowacyjnych rozwiązań:
              rewolucyjnej formuły 7 peptydów i kwasu hialuronowego oraz
              specjalnie opracowanej, opatentowanej technologii.
            </Paragraph>
            <Paragraph noMarginBottom>
              <b>Mezoterapia igłowa skóry głowy</b> znajduje zastosowanie w:
            </Paragraph>
            <List>
              <ListItem>Zapobieganiu wypadaniu włosów</ListItem>
              <ListItem>Łysieniu plackowatemu</ListItem>
              <ListItem>Wzmacnianiu ukorzenienia włosów</ListItem>
              <ListItem>Leczeniu łupieżu</ListItem>
              <ListItem>Zagęszczeniu włosów</ListItem>
              <ListItem>
                Poprawie kondycji rzadkich i cienkich włosów pozbawionych blasku
              </ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu</Subtitle>
            <Paragraph>
              Zabieg zawsze poprzedzony jest indywidualną konsultacją lekarza
              medycyny estetycznej.Trwa od 30 minut do 1h. Przy pomocy cienkiej
              igły wykonuje się iniekcje wprowadzając depozyty preparatu do
              skóry właściwej.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Po jakim czasie i jak długo widoczne są efekty?</Subtitle>
            <Paragraph>
              Po pierwszym zabiegu, znaczna redukcja objawów wypadania włosów.
              Po kolejnych zabiegach stopniowe odrastanie włosów, poprzez
              rewitalizację cebulek włosowych i stymulację krążenia, zwiększanie
              gęstości i grubości włosów, nawilżanie i odżywianie włosów oraz
              skóry głowy.
            </Paragraph>
            <Paragraph>
              Dokładna ilość zabiegów mezoterapii igłowej skóry głowy ustalana
              jest z lekarzem w zależności od nasilenia problemów pacjenta z
              włosami. W przypadku zastosowania regenerującego włosy koktajlu
              zaleca się wykonanie minimum 6-8 serii co 7-14 dni. Po tym czasie
              należy wykonywać jeden zabieg miesięcznie, co pozwoli podtrzymać
              efekty. Terapia peptydowa trwa 2 miesiące i wymaga 4 sesji w
              odstępach co 2 tygodnie. Dla podtrzymania efektu zaleca się zabieg
              przypominający po 6 miesiącach.
            </Paragraph>
            <ImageWrapper>
              <Image
                src={images.first.childImageSharp}
                alt="Mezoterapia igłowa skóry głowy przed po"
              />
              <Image
                src={images.second.childImageSharp}
                alt="Mezoterapia igłowa skóry głowy przed po"
              />
              <Image
                src={images.third.childImageSharp}
                alt="Mezoterapia igłowa skóry głowy przed po"
              />
            </ImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena</Subtitle>
            <Paragraph>
              Ceny zabiegów zaczynają się od 400 zł. Przy pierwszej wizycie
              odbierz swoją kartę lojalnościową i otrzymaj -30% na czwarty
              zabieg medycyny estetycznej wykonany w Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania</Subtitle>
            <List>
              <ListItem>Kobiety w ciąży i karmiące</ListItem>
              <ListItem>Uczulenie na którykolwiek ze składników</ListItem>
              <ListItem>Zmiany skórne, choroby skóry, infekcje</ListItem>
              <ListItem>
                Przyjmowanie leków immunosupresyjnych, terapia sterydowa
              </ListItem>
              <ListItem>Choroby autoimmunologiczne</ListItem>
              <ListItem>Cukrzyca</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer12;

export const Head = () => <SEO tags={TAGS.leczenieLysienia} />;
